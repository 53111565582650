@font-face {
  font-family: "Arcade";
  src: url("../public/ARCADE.TTF");
}  

html {
  font-family: 'Arcade', monospace;
  background-color: #bb81be;
}

.hand {
  -webkit-animation: wiggle 0.5s linear infinite;
  -moz-animation: wiggle 0.5s linear infinite;
  -ms-animation: wiggle 0.5s linear infinite;
  animation: wiggle 0.5s linear infinite;
}


$outline-width: 6px;
$shadow-width: 4px;

$button-background: #92CD41;
$sec-button-background: #76c442;
$shadow-color:  #4AA52E;

$alt-button-background: #F7D51D;
$alt-sec-button-background: #F2C409;
$alt-shadow-color:  #E59400;

$reset-button-background: #E76E55;
$reset-sec-button-background: #CE372B;
$reset-shadow-color:  #8C2022;

.eightbit-btn {
  font-size: 16px !important;
    background: $button-background !important;
    display: inline-block;
    position: relative;
    text-align: center;
    border-radius: 0 !important;
    font-size: 30px;
    padding: 0px;
    text-decoration: none; 
    color: white;
    box-shadow: inset (-$shadow-width) (-$shadow-width) 0px 0px $shadow-color;

    &:hover,
    &:focus {
        background: $sec-button-background !important;
        box-shadow: inset (-$shadow-width*1.5) (-$shadow-width*1.5) 0px 0px $shadow-color;
    }

    &:active {
        box-shadow: inset $shadow-width $shadow-width 0px 0px $shadow-color;
    }

    &:before,
    &:after {
    content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        box-sizing: content-box;
    }

    &:before {
        top: -$outline-width;
        left: 0;
        border-top: $outline-width black solid;
        border-bottom: $outline-width black solid;
    }

    &:after {
        left: -$outline-width;
        top: 0;
        border-left: $outline-width black solid;
        border-right: $outline-width black solid;
    }

    &--reset {
        background: $reset-button-background !important;
        box-shadow: inset (-$shadow-width) (-$shadow-width) 0px 0px $reset-shadow-color;

        &:hover,
        &:focus {
            background: $reset-sec-button-background !important;
            box-shadow: inset (-$shadow-width*1.5) (-$shadow-width*1.5) 0px 0px $reset-shadow-color;
        }

        &:active {
            box-shadow: inset $shadow-width $shadow-width 0px 0px $reset-shadow-color;
        }

    }

    &--proceed {
        background: $alt-button-background !important;
        box-shadow: inset (-$shadow-width) (-$shadow-width) 0px 0px $alt-shadow-color;

        &:hover,
        &:focus {
            background: $alt-sec-button-background !important;
            box-shadow: inset (-$shadow-width*1.5) (-$shadow-width*1.5) 0px 0px $alt-shadow-color;
        }

        &:active {
            box-shadow: inset $shadow-width $shadow-width 0px 0px $alt-shadow-color;
        }

    }
}

.yourDisabledHand {
  opacity: 0.5;
}

.disabledHand {
  -webkit-animation: wiggle 0.5s linear infinite;
  -moz-animation: wiggle 0.5s linear infinite;
  -ms-animation: wiggle 0.5s linear infinite;
  animation: wiggle 0.5s linear infinite;
}

.yourHand {
  cursor: pointer;
}

.yourHand:active:hover, .yourHand:hover {
  -webkit-animation: wiggle 0.5s linear infinite;
  -moz-animation: wiggle 0.5s linear infinite;
  -ms-animation: wiggle 0.5s linear infinite;
  animation: wiggle 0.5s linear infinite;
}

.title {
  overflow: hidden;  
  max-height: 500px;
  transition: max-height 0.5s;
  font-family: 'Arcade', monospace;
}

.balance {
  -webkit-animation: pulse 1s linear infinite;
  -moz-animation: pulse 1s linear infinite;
  -ms-animation: pulse 1s linear infinite;
  animation: pulse 1s linear infinite;
}

.titleHidden {
  overflow: hidden;  
  max-height: 0px;
  margin-top: -100px;
  transition: all 0.5s;
}


.balanceBox {
  overflow: hidden;  
  max-height: 500px;
  height: 92px;
  transition: max-height 0.5s;
  font-family: 'Arcade', monospace;
}


.balanceBoxHidden {
  overflow: hidden;  
  max-height: 0px;
  transition: all 0.5s;
}

.bg {
  position: absolute;
  top:0;
  bottom: 0;
  left:0;
  right:0;
  opacity: 0.3;
  background-image: url(../public/tile2.png), url(../public/tile1.png), url(../public/tile5.png);
  -webkit-animation: snow 1000s linear infinite;
  -moz-animation: snow 1000s linear infinite;
  -ms-animation: snow 1000s linear infinite;
  animation: snow 1000s linear infinite;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  -o-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

@keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  
  100% {
    background-position: 50000px 50000px, 10000px 20000px, -10000px 15000px;
  }
}

@keyframes pulse {
  0% { 
    transform: scale(1);
  }
  50% { 
    transform: scale(0.9);
  }

}

@keyframes wiggle {
  0% { 
    transform: scale(1);
    transform: rotate(0deg); 
  }
  30% { 
    transform: scale(1);
    transform: rotate(0deg); 
  }
  50% { 
    transform: scale(0.5);
    transform: rotate(10deg) scale(0.8); 
  }
  70% { 
    transform: scale(0.5);
    transform: rotate(-10deg) scale(0.8); 
  }
  100% { 
    transform: scale(1);
    transform: rotate(0deg); 
  }
}

@keyframes shake {
  0% { 
    transform: scale(1);
    transform: rotate(0deg); 
  }
  30% { 
    transform: scale(1);
    transform: rotate(0deg); 
  }
  50% { 
    transform: rotate(25deg) scale(0.9); 
  }
  70% { 
    transform: rotate(-25deg) scale(0.9); 
  }
  100% { 
    transform: scale(1);
    transform: rotate(0deg); 
  }
}

.hand1 {
  -webkit-animation: shake 0.5s  linear 0.1s infinite;
  -moz-animation: shake 0.5s  linear 0.1s infinite;
  -ms-animation: shake 0.5s  linear 0.1s infinite;
  animation: shake 0.5s  linear 0.1s infinite;
}

.hand2 {
  -webkit-animation: shake 0.5s linear infinite;
  -moz-animation: shake 0.5s linear infinite;
  -ms-animation: shake 0.5s linear infinite;
  animation: shake 0.5s linear infinite;
}

.greenBlink{
  display: inline-block;
  animation: greenBlink 2s infinite;
}

@keyframes greenBlink{
  0%		{
    color: inherit;
  }
  25%		{
    color: green;
  }
  75%		{
    color: inherit;
  }
}

.redBlink{
  display: inline-block;
  animation: redBlink 2s infinite;
}

@keyframes redBlink{
  0%		{
    color: inherit;
  }
  25%		{
    color: red;
  }
  75%		{
    color: inherit;
  }
}